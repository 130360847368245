import WidgetType = ToroEnums.WidgetType;

import { SaArea, SaStation } from '../../api/spatial-adjust/models/spatial-adjust-course.model';
import { Injectable } from '@angular/core';
import { IWidgetDataSettings } from '../../api/_common/interfaces/widget-data-settings.interface';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { ToroEnums } from '../enumerations/toro.enums';
import { ToroNotification } from '../../api/notifications/models/toro-notification.model';
import { WidgetDataSettings } from '../../core/dashboard/widgets/_models/widget-data-settings.model';
import { WidgetSelectItemValue } from '../../api/widgets/models/widget-select-item-value.model';


@Injectable({
    providedIn: 'root'
})
export class BroadcastService {

    // =========================================================================================================================================================
    // Dashboard Events
    // =========================================================================================================================================================

    removeWidget = new Subject<{ widgetId: number, widgetTypeId: WidgetType }>();
    showWidgetSelector = new Subject<SelectItem[]>();
    widgetSelectionChange = new Subject<WidgetSelectItemValue[]>();
    toggleSystemOverlay = new Subject<{ show: boolean, text?: string }>();
    changeWidgetConstraint = new Subject<{id: number, maxRows: number, maxCols: number}>();
    toggleWidgetDrag = new Subject<{isLocked: boolean}>();
    showFooter = new Subject<boolean>();
    appReactivate = new Subject();  // App brought back from background, minimized, etc.

    // =========================================================================================================================================================
    // Widgets
    // =========================================================================================================================================================

    showDataSettingsDialog = new Subject<IWidgetDataSettings>();
    widgetDataSettingsChange = new Subject<WidgetDataSettings>();

    // =========================================================================================================================================================
    // User Manager
    // =========================================================================================================================================================

    // This event is fired after DashUser Preferences have been updated in the AuthManagerService. This is the only event
    // that should be subscribed to to affect UI changes (e.g., language, units, etc.) based on user preferences changes.
    userPreferencesChange = new Subject();

    // =========================================================================================================================================================
    // Lynx
    // =========================================================================================================================================================

    lynxTopologyUpdatePending = new Subject<boolean>();

    // =========================================================================================================================================================
    // Turf Guard
    // =========================================================================================================================================================

    turfGuardTopologyUpdatePending = new Subject<boolean>();

    // =========================================================================================================================================================
    // myTurf
    // =========================================================================================================================================================

    myTurfUserSignedIn = new Subject();

    // =========================================================================================================================================================
    // Notifications Service
    // =========================================================================================================================================================

    notificationsRetrieved = new Subject<ToroNotification[]>();

    // =========================================================================================================================================================
    // Setup Wizard
    // =========================================================================================================================================================

    setupDoConnect = new Subject();
    setupToggleWidgetState = new Subject<{type: WidgetType, isEnabled: boolean}>();
    setupCompleteIntegration = new Subject();
    showSetupWizard = new Subject();
    setupTaskTrackerApiKeyChanged = new Subject<string>();
    setupPlaybooksCourseIdChanged = new Subject<string>();
    setupPerryWeatherIntegrationKeyChanged = new Subject<string>();
    setupSoilScoutLoginChanged = new Subject();

    // =========================================================================================================================================================
    // Spatial Adjust
    // =========================================================================================================================================================

    spatialAdjustLoaded = new Subject<boolean>();
    spatialAdjustDataLoaded = new Subject<SaArea[]>();
    mapStationSelected = new Subject<string>();
    itemEnableOrValueChanged = new Subject();
    pushingAdjustmentsToLynx = new Subject();
    pendingAdjustChangesUpdated = new Subject<SaStation[]>();
}
